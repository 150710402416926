import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { isAndroid, isIOS } from "react-device-detect";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { useSearchParams } from "react-router-dom";

import { db } from "../firebase-config";

import "./Header.css";

function Header({ user, homeScreen, onClickSignIn }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const onDownloadPress = async () => {
    const downloadLinkClicksRef = collection(db, "gbWaitlist");
    console.log("downloadclicked3");
    try {
      const newClick = await addDoc(downloadLinkClicksRef, {
        createdAt: serverTimestamp(),
        buttonLocation: "header",
        os: isAndroid ? "android" : isIOS ? "ios" : "unknown",
        utm_source: searchParams.get("utm_source"),
        utm_medium: searchParams.get("utm_medium"),
        utm_campaign: searchParams.get("utm_campaign"),
      });
      window.location.replace(
        "https://apps.apple.com/us/app/gymbuddy-connect-workout/id6446038725"
      );
    } catch (error) {
      console.log("eror", error);
    }
  };

  return (
    <nav className="headerContainery slide-down">
      <Link style={{ maxWidth: "50%", width: 200 }} to="/">
        <img
          className="header_icon"
          src={require("../assets/gLogo2.png")}
          alt="GymBuddy Logo"
          aria-label="Redirect back to homepage"
        />
      </Link>

      <div className="headerRight">
        <Button
          variant="primary"
          size="md"
          style={{
            borderRadius: 30,
          }}
          onClick={onDownloadPress}
          className="header-download-button"
          aria-label="Opens a link for mobile download"
        >
          Try now 💪
        </Button>
      </div>
    </nav>
  );
}

export default Header;
