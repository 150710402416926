import React from "react";
import "./FeatureBrief.css";
function FeatureBrief() {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("show-feature-brief");
      }
    });
  });
  const hiddenElements = document.querySelectorAll(".hidden");
  hiddenElements.forEach((el) => observer.observe(el));

  return (
    <div className="feature-brief side-padding">
      <h2 className="fs-800 fw-bold feature-brief-title">
        How we get you gyming
        <span className="feature-brief-emoji">👇</span>
      </h2>
      <img
        className="background-gradient"
        alt=""
        src={require("../assets/color-wheel-dark.svg").default}
        role="presentation"
      ></img>
      <ol className="feature-brief-list">
        <li className="feature-brief-item hidden">
          <img
            alt="A screen on a phone showing how Gym Buddy works with your schedule."
            src={require("../assets/howItWorks1.png")}
            style={{ width: 250, height: "auto" }}
            className="feature-brief-pic"
          ></img>
          <div className="feature-brief-step">
            <div className="numbered-circle">
              <div className="circle-number">1</div>
            </div>
            <div className="feature-brief-text" style={{ maxWidth: 500 }}>
              <h3
                className="numbered-item fs-600"
                style={{ fontWeight: "bold", color: "#2b2e32" }}
              >
                Create an account
              </h3>
              <p className="feature-brief-caption">
                Enter your schedule for the week and select a gym that you’d
                like to start working out at.
              </p>
            </div>
          </div>
        </li>
        <li className="feature-brief-item reverse hidden">
          <div className="feature-brief-step">
            <div className="numbered-circle">
              <div className="circle-number">2</div>
            </div>
            <div className="feature-brief-text" style={{ maxWidth: 500 }}>
              <h3
                className="numbered-item fs-600"
                style={{ fontWeight: "bold", color: "#2b2e32" }}
              >
                {" "}
                Get paired and workout
              </h3>
              <p className="feature-brief-caption">
                We will pair you up with a gymbuddy and set a time for you to
                workout together (free gym day pass provided).
              </p>
            </div>
          </div>

          {/* <img alt="" src="phone-2.png"></img> */}
          <img
            alt="A phone screen of what it looks like to find a GymBuddy match"
            src={require("../assets/howItWorks2.png")}
            style={{ width: 250, height: "auto" }}
            className="feature-brief-pic"
            loading="lazy"
          ></img>
        </li>
        <li className="feature-brief-item hidden">
          <img
            alt="A phone screen showing an example of receiving a gym membership
            discount through GymBuddy"
            src={require("../assets/howItWorks3.png")}
            style={{ width: 200, height: "auto" }}
            loading="lazy"
            className="feature-brief-pic"
          ></img>{" "}
          <div className="feature-brief-step">
            <div className="numbered-circle">
              <div className="circle-number">3</div>
            </div>
            <div className="feature-brief-text" style={{ maxWidth: 500 }}>
              <h3
                className="numbered-item fs-600"
                style={{ fontWeight: "bold", color: "#2b2e32" }}
              >
                Stay motivated and save big
              </h3>
              <p className="feature-brief-caption">
                Receive up to 40% off when you and your gymbuddy both purchase
                your gym memberships through our app.
              </p>
            </div>
          </div>
        </li>
      </ol>
    </div>
  );
}
export default FeatureBrief;
