import React from "react";

export default function Support() {
  return (
    <div className="privacyPolicyDiv" style={{ minHeight: "100vh" }}>
      <div
        // style={{
        //   display: "flex",
        //   alignItems: "center",
        //   backgroundColor: "red",
        // }}
        style={{ textAlign: "center" }}
      >
        <img
          style={{
            objectFit: "contain",
            height: 50,
            borderRadius: 10,
            // marginTop: 20,
            marginBottom: 10,
            //   margin: "auto    ",
          }}
          src={require("../assets/icon.png")}
          // src='https://firebasestorage.googleapis.com/v0/b/clothing-for-ukraine.appspot.com/o/Clothing%20For%20Ukraine%20Logo.jpg?alt=media&token=c75af262-0d8a-4184-b18e-3b7de7fd5363'
          alt=""
        />
        <img
          style={{
            objectFit: "contain",
            width: 50,
            height: 50,
            borderRadius: 10,
            // marginTop: 20,
            marginBottom: 10,
            //   margin: "auto    ",
            backgroundColor: "red",
          }}
          src="https://us-central1-gymbuddy-50c1b.cloudfunctions.net/testPixelCall/supportWebPage"
          // src="https://us-central1-gymbuddy-50c1b.cloudfunctions.net/testPixelCall"
          // src="https://getgymbuddy.com/PixelTest"
          // src="https://firebasestorage.googleapis.com/v0/b/gymbuddy-50c1b.appspot.com/o/files%2F0-db5f3175-95cb-4469-8357-2d50fd68b45a?alt=media&token=a6a07e6a-83cb-41df-853a-f50db5d1af29"
          // src='https://firebasestorage.googleapis.com/v0/b/clothing-for-ukraine.appspot.com/o/Clothing%20For%20Ukraine%20Logo.jpg?alt=media&token=c75af262-0d8a-4184-b18e-3b7de7fd5363'
          alt=""
        />
      </div>
      {/* <div style={{ textAlign: "center", marginBottom: 10 }}>
        <img
          //   className="homeImage"
          style={{ objectFit: "contain", maxWidth: "500px", borderRadius: 10 }}
          src={require("../assets/homePic.jpg")}
          // src='https://firebasestorage.googleapis.com/v0/b/clothing-for-ukraine.appspot.com/o/Clothing%20For%20Ukraine%20Logo.jpg?alt=media&token=c75af262-0d8a-4184-b18e-3b7de7fd5363'
          alt=""
        />
      </div> */}
      <h1 className="privacyParagraph">Welcome to Gymbuddy Support</h1>
      <p className="privacyParagraph">
        Gymbuddy is a new mobile app that allows college students to find other
        students at their university to workout with.
      </p>
      <p className="privacyParagraph boldTitle">Contact Us</p>
      <p className="privacyParagraph">Email: hellogymbuddy@gmail.com</p>
      <p className="privacyParagraph">
        We are here to help! Feel free to email us with any feedback, comments,
        or questions you have!
      </p>
    </div>
  );
}
