import React, { useEffect } from "react";
import {
  addDoc,
  collection,
  serverTimestamp,
  doc,
  getDoc,
  query,
  where,
  getDocs,
  arrayUnion,
  updateDoc,
} from "firebase/firestore";
import moment from "moment";
import { useSearchParams, useNavigate } from "react-router-dom";

import { db } from "../firebase-config";

export default function PixelTest() {
  // useEffect(() => {
  console.log("hello 2");

  const qrScansRef = collection(db, "pixelScans");

  const newScan = addDoc(qrScansRef, {
    createdAt: serverTimestamp(),
    content: "scan",
  });
  // }, []);

  // return (
  //   <img
  //     style={{
  //       objectFit: "contain",
  //       height: 50,
  //       borderRadius: 10,
  //       // marginTop: 20,
  //       marginBottom: 10,
  //       //   margin: "auto    ",
  //     }}
  //     // src={require("../assets/icon.png")}
  //     src="https://getgymbuddy.com/static/media/gLogo2.87c60a2b3350e293b25f.png"
  //     alt=""
  //   />
  // );
}
