import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Stack, Button } from "react-bootstrap";
import { isAndroid, isIOS } from "react-device-detect";
import {
  addDoc,
  collection,
  serverTimestamp,
  doc,
  getDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { useSearchParams, useNavigate } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";

import { db } from "../firebase-config";
import "./Footer.css";

function Footer() {
  const [searchParams, setSearchParams] = useSearchParams();

  const onDownloadPress = async () => {
    const downloadLinkClicksRef = collection(db, "gbWaitlist");
    console.log("waitlistclicked3");
    try {
      const newClick = await addDoc(downloadLinkClicksRef, {
        createdAt: serverTimestamp(),
        buttonLocation: "footer",
        os: isAndroid ? "android" : isIOS ? "ios" : "unknown",
        utm_source: searchParams.get("utm_source"),
        utm_medium: searchParams.get("utm_medium"),
        utm_campaign: searchParams.get("utm_campaign"),
      });
      // window.open(`https://forms.gle/VfNiYs2qAvnwmmyXA`);
      // window.location.replace("https://forms.gle/VfNiYs2qAvnwmmyXA");
      window.location.replace(
        "https://apps.apple.com/us/app/gymbuddy-connect-workout/id6446038725"
      );
      // window.open(
      //   "https://apps.apple.com/us/app/gymbuddy-connect-workout/id6446038725",
      //   "_blank",
      //   "noreferrer"
      // );
    } catch (error) {
      console.log("eror", error);
    }
  };
  return (
    <footer className="side-padding footer">
      <div className="contact-us">
        <a
          style={{ marginTop: 20, textAlign: "center" }}
          href="mailto: hellogymbuddy@gmail.com"
          className="email"
          aria-label="Email our support team!"
        >
          hellogymbuddy@gmail.com
        </a>
        <Button
          variant="primary"
          size="md"
          style={{
            alignSelf: "center",
            flexDirection: "row",
            backgroundColor: "white",
            color: "black",
            borderWidth: 0,
            borderRadius: 30,
            width: "max-content",
            padding: 10,
            paddingInline: 15,
          }}
          onClick={onDownloadPress}
          className="header-download-button"
          aria-label="Opens a link for mobile download"
        >
          Try now 💪
        </Button>
      </div>
    </footer>
  );
}

export default Footer;
